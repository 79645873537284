@import url('https://fonts.googleapis.com/css2?family=Sulphur+Point:wght@300;400;700&display=swap');
* {
    font-family: 'Sulphur Point', sans-serif;
    font-weight: 400;
    line-height: 0.4;
    -webkit-appearance: none;
}

body {
    background-color: #00004b;
    color: #ffffff;
    font-weight: 400;
    font-size: 3vh;
}

select {
    appearance: none;
}

.content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    text-align: center;
    align-content: center;
    height: auto;
    /*gap: 2vh;*/
}

.item {
    display: flex;
    flex-direction: column;
    line-height: 1px;
}

.bill {
    display: flex;
    gap: 2vw;
    }

.flexboxHeadingHalf {
    margin-bottom: -0.5vh;
}

.flexboxHeading2 {
    margin-bottom: -2vh;
}

.currencyBox {
    border-style: solid;
    border-radius: 2vh;
    width: 7vh;
    height: 7vh;
    background-color: #00004b;
    color: #ffffff;
    font-weight: 400;
    font-size: 4vh;
    align-self: center;
    text-align: center;
    display: flex;
}

input, .currency {
    border: none;
    border-radius: 2vh;
    max-height: 6vh;
    background-color: transparent;
    color: #ffffff;
    font-weight: 400;
    font-size: 4vh;
    height: 6.8vh;
    width: 6.8vh;
    align-self: center;
    text-align: center;
    line-height: 4vh;
    outline:none;
    -webkit-appearance: none;
}

.chf {
    font-size: 3vh;
    height: 6vh;
    padding-top: 1vh;
}

.billAmount {
    border-style: solid;
    border-radius: 2vh;
    width: 45vw;
    height: 7vh;
    background-color: #00004b;
    color: #ffffff;
    font-weight: 400;
    font-size: 5vh;
    align-self: center;
    text-align: center;
    display: flex;
}

.numericFormat {
    justify-content: center;
    width: 40vw;
    border: none;
    background-color: transparent;
    outline:none
}

.tipButton, .tipSelected {
    border-style: solid;
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    font-size: 10vw;
    padding-top: 2vw;
    padding-right: 0; /*for iOS*/
    padding-left: 0;  /*for iOS*/
}

.tipSelected {
    background-color: #ffffff;
    color: #00004b;
}

.tipButton {
    background-color: #00004b;
    color: #ffffff;
}

.tipButtons{
    padding-bottom: 2vh;
    display: flex;
    justify-content: space-around;
}

.adjNumber {
    padding-top: 2vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.plusMinusButton, .displayNumber {
    border-style: solid;
    border-width: medium;
    height: 5vh;
    border-color: #ffffff;
    background-color: #00004b;
    color: #ffffff;
    border-radius: 1vh;
}

.plusMinusButton {
    font-size: 3vh;
    width: 5vh;
    padding: 0;
}

.displayNumber {
    width: 30vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottomButtons {
    padding-top: 3vh;
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.calculate, .inactiveLargeButton, .reset, .goBack, .roundUpLarge{
    border-style: solid;
    border-width: medium;
    border-color: #ffffff;
    border-radius: 1vh;
}

.calculate, .inactiveLargeButton, .goBack, .roundUpLarge {
    height: 5vh;
    width: 60vw;
    font-size: 4vh;
    align-self: center;
}

.roundUpLarge {
    font-size: 3vh;
}

.calculate, .goBack, .roundUpLarge {
    background-color: #ffffff;
    color: #00004b;
}

.inactiveLargeButton {
    border-color: #828282;
    background-color: #00004b;
    color: #828282;
}

.reset {
    height: 3vh;
    width: 20vw;
    background-color: #00004b;
    color: #ffffff;
    font-size: 2vh;
    align-self: flex-end;
}

.calculation {
    font-size: 4vh;
}

::selection {
    background: red;
    border-color: red;
}

@media screen  and (min-width: 1000px) {
    .bill {
        gap: 20px;
    }
    input, .currency {
        border-radius: 20px;
        max-height: 60px;
        font-size: 40px;
    }
    .currency {
        max-width: 150px;
    }
    input {
        max-width: 450px;
    }
    .tipButton, .tipSelected {
        width: 200px;
        height: 200px;
        font-size: 100px;
        padding-top: 20px;
    }
    .displayNumber {
        width: 300px;
    }
    .calculate, .inactiveLargeButton, .goBack, .roundUpLarge {
        width: 600px;
    }
    .reset {
        width: 200px;
    }
}
